export default Object.freeze([
	{
		id: 3,
		tallerID: 7,
		name: 'Carlos Joseph Gutiérrez Sánchez',
		shortName: 'Güero',
		order: 1,
	},
	{
		id: 2,
		tallerID: 2,
		name: 'Araceli Sandoval',
		shortName: 'Araceli',
		order: 2,
	},
	{
		id: 8,
		tallerID: 9,
		name: 'Armando Galarza',
		shortName: 'Chelas',
		order: 6,
	},
	{
		id: 9,
		tallerID: 10,
		name: 'Axel Galarza',
		shortName: 'Axel',
		order: 4,
	},
	{
		id: 18,
		tallerID: 14,
		name: 'Juan Francisco Javier Gaucín Jaramillo',
		shortName: 'Gaucín',
		order: 1,
	},
	{
		id: 22,
		tallerID: 15,
		name: 'Laura Marcela Gutierrez Villanueva',
		shortName: 'Laura',
		order: 5,
	},
	{
		id: 33,
		tallerID: 27,
		name: 'Juan Carlos Fernandez Martinez',
		shortName: 'Carlos',
		order: 3,
	},
	{
		id: 44,
		tallerID: 35,
		name: 'Juan Carlos',
		shortName: 'Juan Carlos',
		order: 8,
	},
])

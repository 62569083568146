export const cashFlowStructure = {
	cash_flow_type: 'in',
	cashflow_action_id: 0,
	cashflow_description: '',
	issuing: '',
	receiver: '',
	cash: '',
	bank_transfer: '',
	bank_terminal_credit: '',
	bank_terminal_debit: '',
	other: '',
	other_details: '',
	user_information: {},
	comments: '',
	purchase_order: '',
}
